import React from "react"
import "../../components/animations/scroll.scss"
import PropTypes from "prop-types"
import TheaterVideo from "../../components/Theater/TheaterVideo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

function StaticHeading({ heading, subheading, youtube, language }) {
  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading"
    >
      {/* <h1 id="SEOTitle">{seoHeading}</h1>
      <div className="mobile-headline">
        <h2 className="h1-style">{mobileHeading}</h2>
      </div> */}
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset", textAlign: "left" }}
      >
        <div className="curtain-heading-container">
          <h1
            className="curtain-heading h3-style"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </div>
        <MarkdownViewer
          className="above-text__subheader"
          markdown={subheading}
        />
      </div>

      {youtube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          controls
          playing
          onlyButton
          sizzleButton
          buttonClass="sizzle-button-under contained"
          compId="sizzleTheaterButton"
          language={language}
        />
      )}
    </div>
  )
}

StaticHeading.propTypes = {
  post: PropTypes.object.isRequired,
  language: PropTypes.string
}

export default StaticHeading
